<template>
  <el-dialog
    title="选择商家"
    ref="dialog"
    :model-value="dialogVisible"
    :before-close="handleClose"
    width="70%"
    :close-on-click-modal="false"
    :append-to-body="true"
    :z-index="1000"
  >
    <div class="search">
      <el-select v-model="category_id" placeholder="请选择行业" clearable>
        <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-input v-model="name" type="text" placeholder="请输入商家名称" clearable></el-input>
      <el-button @click="pageEmit(1)">搜索</el-button>
    </div>

    <el-table
      ref="table"
      :data="tableData.items"
      v-loading="loading"
      max-height="500"
      @select="handleSelect"
      @select-all="handleSelect"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column label="商家logo" width="100">
        <template #default="scope">
          <img class="logo-img" :src="scope.row.logo_img?.url || defaultLogo" alt="商家logo" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="商家名称"></el-table-column>
      <el-table-column prop="mchnt_cd" label="商家号"></el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
    </el-table>

    <div class="pagination">
      <div class="tips">已选择{{ choosed.length }}位商家</div>
      <page
        @page-event="pageEmit"
        :current-page="pageParam.currentPage"
        :total="tableData.total"
        :page-size="tableData.count"
      ></page>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, reactive, nextTick, toRaw } from 'vue'
import merchantModel from '@/model/merchant'
import { ElMessage } from 'element-plus/lib/components'
import defaultLogo from '@/assets/image/default-logo.png'
import commonHandle from '@/hooks/useCommon'

export default {
  emits: ['confirm'],
  props: {
    list: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const dialogVisible = ref(false)
    const table = ref(null)
    const choosed = ref([])
    const cateList = ref([])
    const name = ref()
    const category_id = ref()

    const init = data => {
      dialogVisible.value = true
      choosed.value = JSON.parse(JSON.stringify(data))
      getData(null, data)
      getMerCateListAll()
    }

    // 获取行业分类
    const getMerCateListAll = async () => {
      const res = await merchantModel.getMerCateListAll()
      cateList.value = res
    }

    // 获取商家列表
    const getData = async (pageQuery, data = []) => {
      loading.value = true
      let res
      try {
        res = await merchantModel.getMerchantList({
          page: pageQuery?.page || pageParam.currentPage - 1,
          count: pageQuery?.count || pageParam.count,
          name: name.value,
          category_id: category_id.value,
        })
        tableData.value = res
        dataSelected()
      } finally {
        loading.value = false
      }
    }

    // 回显选中商家
    const dataSelected = () => {
      const ids = choosed.value.map(item => item.id)
      if (ids.length) {
        nextTick(() => {
          tableData.value.items.forEach(item => {
            const boole = ids.indexOf(item.id) > -1
            table.value.toggleRowSelection(item, boole)
          })
        })
      }
    }

    // 选择商家
    const handleSelect = val => {
      let defaultVal = choosed.value
      // 先清空选过的
      tableData.value.items.forEach(elem => {
        let idx = defaultVal.findIndex(item => item.id == elem.id)
        if (idx > -1) {
          defaultVal.splice(idx, 1)
        }
      })
      // 再将数据合并 防止数据重复
      choosed.value = defaultVal.concat(val.map(item => toRaw(item)))
    }

    // 关闭弹窗
    const handleClose = () => {
      dialogVisible.value = false
    }

    // 保存选择
    const handleConfirm = () => {
      console.log(choosed.value)
      if (!choosed.value.length) return ElMessage.warning('请选择商家')
      ctx.emit('confirm', choosed.value)
      handleClose()
    }

    // 获取行标识
    const getRowKeys = row => row.id

    const { tableData, loading, pageParam, pageEmit } = commonHandle(getData)

    return {
      dialogVisible,
      loading,
      tableData,
      pageParam,
      table,
      name,
      category_id,
      cateList,
      choosed,
      defaultLogo,
      init,
      handleSelect,
      handleClose,
      handleConfirm,
      pageEmit,
      getRowKeys,
    }
  },
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  ::v-deep(.el-input) {
    width: 220px;
  }
  ::v-deep(.el-select) {
    width: 220px !important;
  }
}
.logo-img {
  width: 50px;
  height: 50px;
}
.pagination {
  margin-top: 20px;
  flex-wrap: wrap;
  @include flex-between-align;
}
.tips {
  margin-top: 20px;
}
.el-table {
  border-bottom: 1px solid #dee2e6;
}
</style>
