import { ref, reactive } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus/lib/components'

export default function(getData, deleteFun) {
    const pageParam = reactive({
        currentPage: 1,
        page: 0,
        count: 10,
    })
    const loading = ref(false)
    const tableData = ref([])

    // 翻页
    const pageEmit = (page) => {
        const reqPage = page-1;
        pageParam.currentPage = page
        getData({page:reqPage, count:tableData.value.count})
    }

    // 删除
    const handleDelete = (id, text = '此操作将永久删除该项, 是否继续?') => {
        let res
        ElMessageBox.confirm(text, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(async () => {
            try {
                loading.value = true
                res = await deleteFun(id)
            } catch (e) {
                loading.value = false
                console.error(e)
            }
            if (res.code < window.MAX_SUCCESS_CODE) {
                loading.value = false
                if (tableData.value.total % pageParam.count === 1 && pageParam.page !== 0) {
                    // 判断删除的是不是每一页的最后一条数据
                    pageParam.page--
                }
                await getData()
                ElMessage.success(`${res.message}`)
            } else {
                loading.value = false
                ElMessage.error(`${res.message}`)
            }
        })
    }

    return {
        pageParam,
        loading,
        tableData,
        pageEmit,
        handleDelete
    }
}

